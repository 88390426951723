import React, { useState, useEffect, useCallback, useRef } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import Container from './Container'

const App = () => {
  const [invert, setInvert] = useState(false)
  const [buttonVisibility, setButtonVisibility] = useState('hide')
  const [cursorVisibility, setCursorVisibility] = useState('none')
  const [isHovering, setIsHovering] = useState(false)
  const handle = useFullScreenHandle();
  const { enter, exit, active } = handle
  let timerRef = useRef(null)

  const toggleFull = useCallback(() => {
    active ? exit() : enter()
    setInvert((prevInvert) => !prevInvert)
  }, [active, enter, exit, setInvert])



  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'f') {
        toggleFull()
      }
    }

    document.addEventListener('keydown', handleKeyPress)

    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [toggleFull])

  const hideOnClick = () => {
    setCursorVisibility('none')
    setButtonVisibility('hide')
  }

  const onMouseMove = () => {
    setCursorVisibility('default')
    setButtonVisibility('display')
    if (timerRef) clearTimeout(timerRef)
    if (!isHovering) {
      timerRef = setTimeout(() => {
        setCursorVisibility('none')
        setButtonVisibility('hide')
      }, 500)
    }
  }

  const onMouseOver = () => {
    setIsHovering(true)
  }

  const onMouseLeave = () => {
    setIsHovering(false)
  }

  return (
    <div
      className={`App ${cursorVisibility}`}
      onClick={hideOnClick}
      onMouseMove={onMouseMove}
    >
      <Router>
        <FullScreen handle={handle}>
          <div
            id='button'
            className={buttonVisibility}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            onClick={toggleFull}
          >
            {!invert ? '< >' : '> <'}
          </div>
          <Container cursorVisibility={cursorVisibility} hideOnClick={hideOnClick} />
        </FullScreen>
      </Router>
    </div>
  )
}

export default App
